<div class="row">
    <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">{{localFileName}}</h4>
                <!-- <ngx-doc-viewer [url]="'previewFile'" viewer="google" style="width:100%;height:50vh;"></ngx-doc-viewer> -->
                <ngx-doc-viewer [url]="localFilePath" viewer="google" style="width:100%;height:80vh;"></ngx-doc-viewer>
  
                <!-- <iframe style="width:100%;height:70vh;" [src]="previewFile"></iframe> -->
            </div>
        </div>
    </div>
</div>
<div class="page-header">
  <h3 class="page-title">
    Todo list
  </h3>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="javascript:;">Apps</a></li>
      <li class="breadcrumb-item active" aria-current="page">Todo list</li>
    </ol>
  </nav>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <p class="card-title">Todo List</p>
        <app-todo></app-todo>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Profile } from 'src/_models/profile';
import { FileUploadService } from '../_services/fileupload.service';
import { ProfileService } from '../_services/profile.services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from '../common/app.constants';
import { map } from 'rxjs/operators';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-imgresponse',
  templateUrl: './imgresponse.component.html',
  styleUrls: ['./imgresponse.component.scss'],
})
export class ImgresponseComponent implements OnInit {

  subtitle: any
  profileUsers: Profile[] = []
  originBackend = AppConstants.API_URL2;
  maxSize: number = 12;
  bigTotalItems: number = 51;
  bigCurrentPage: number = 1;
  numPages: number = 0;
  constructor(private profileService: ProfileService) {
  }

  ngOnInit() {
    this.getListAllProfile(0, 12)
  }

  getListAllProfile(page: any, size: any) {
    this.profileService.getAllcustomer(page, size).pipe(map((data: any) => data.resultData)).subscribe(
      response => {
        this.profileUsers = response.content
        this.bigTotalItems = response.totalElements
      }, err => {

      }
    )
    
  }
  pageChanged(event: PageChangedEvent): void {
    this.getListAllProfile(event.page-1, 12)
  }

}

<div class="page-header">
  <h3 class="page-title">
    <span class="page-title-icon bg-gradient-primary text-white mr-2">
      <i class="mdi mdi-account-circle"></i>
    </span>
    TẠO AVARTAR CHO BẠN
  </h3>

</div>
<div class="row">
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <!-- <h4 class="card-title">TẠO AVARTAR CHO BẠN</h4> -->
        <canvas id="c"></canvas>
        <div style="margin-top: 20px;margin-left:auto;margin-right: auto; text-align: center;">
          <div class="col-md-6" style="margin-bottom: 10px;margin: 0 auto;">
            <p style="margin-bottom: 5px;">Thu / Phóng</p>
            <input class="slider" id="scaleImage" type="range" min="1" max="100" value="50" />
          </div>
          <div class="col-md-6" style="margin-bottom: 10px;margin: 0 auto;">
            <p style="margin-bottom: 5px;">Xoay</p>
            <input class="slider" id="angleImage" type="range" min="1" max="100" value="1" />
          </div>
          <button (click)="uploader.click()" class="file-upload-browse btn btn-gradient-primary" type="button"><i
              class="mdi mdi-upload"></i> Tải lên</button>
          <input type="file" hidden #uploader id="file" class="file-upload-default"
            (change)="chooseFileImage($event)" />
          <button (click)="removeObject()" class="file-upload-browse btn btn-gradient-primary" type="button"><i
            class="mdi mdi-delete-forever"></i>Xóa Ảnh</button>
          <button id="crop" class="btn btn-gradient-primary btn-fw" title="crop image" (click)="openModal(mediumModalContent)">
            <i class="mdi mdi-download"></i> Tải về
          </button>
        </div>
        <img id="cropimage" src="" alt="" />
      </div>
    </div>
  </div>
</div>
<ng-template #mediumModalContent let-modal class="modal fade" id="exampleModal-2" aria-labelledby="exampleModalLabel-2">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel-2">Hình ảnh kết quả</h5>
      <button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="position: relative;" [style.height]="imgHeightDownload">
      <div class="form-group row">
        <div class="col-sm-12">
          <img id="imageDownload" style="position: absolute;" [style.height]="imgHeightDownload" [style.width]="imgWidthDownload" src="{{imageDownload}}"/>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="box-btn" style="margin: 0 auto;">
        <button type="button" style="margin-bottom: 20; margin-top: 20;" class="btn btn-gradient-primary btn-fw" (click)="downloadImage();">Tải về</button>
      </div>
      <span style="font-size: initial; color: burlywood;">
          Nếu không tải được ảnh:<br>
        - Máy tính: Ấn chuột phải => Chọn "Save Image As..."<br>
        - Điện thoại: Nhấn giữ tấm ảnh từ 5 giây trở lên sẽ hiển thị tùy chọn tải ảnh.
      </span>
    </div>
  </div>
</ng-template>

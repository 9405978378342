<div class="page-header">
    <h3 class="page-title">
        <span class="page-title-icon bg-gradient-primary text-white mr-2">
            <i class="mdi mdi-account-circle"></i>
        </span>
        KỲ VỌNG GỬI ĐẾN ĐẠI HỘI
    </h3>

</div>
<div class="row">
    <!-- <div class="col-md-3 grid-margin stretch-card">
  </div> -->
    <!-- <div class="col-lg-6 col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div id="image-section" style="height: auto; width: 100%;">
          <canvas id="canvas-kyvong"></canvas>
        </div>
      </div>
    </div>
  </div> -->
    <div class="col-lg-12 col-md-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <!-- <h4 class="card-title">Default form</h4> -->
                <p class="card-description">
                    Điền thông tin để tạo ảnh cảm nhận
                </p>
                <form class="forms-sample" name="form" (ngSubmit)="onSubmit()" novalidate>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label for="floatFullName">Họ và tên (Tối đa: {{profile.fullName?.length}}/40 ký
                                    tự)</label>
                                <textarea rows="1" id="floatFullName" name="floatEmail" [(ngModel)]="profile.fullName"
                                    [maxlength]="40" required="" email=""
                                    class="form-control ng-untouched ng-pristine"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="floatPosition">Chức vụ / Đơn vị công tác (Tối đa:
                                    {{profile.postion?.length}}/100 ký
                                    tự)</label>
                                <textarea rows="1" id="floatPosition" name="floatPosition" [maxlength]="100"
                                    [(ngModel)]="profile.postion" required=""
                                    class="form-control ng-untouched ng-pristine"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="floattextArea">Nội dung gửi cảm nhận (Tối đa:
                                    {{profile.content?.length}}/1000 ký tự)</label>
                                <textarea rows="7" id="floattextArea" name="floattextArea" [maxlength]="1000"
                                    [(ngModel)]="profile.content" required=""
                                    class="form-control ng-untouched ng-pristine"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <!-- <div class="card">
                <div class="card-body"> -->
                            <div id="image-section" style="height: auto; width: 100%;">
                                <canvas id="canvas-kyvong"></canvas>
                            </div>
                            <!-- </div>
              </div> -->
                        </div>
                    </div>
                    <div class="row" style="margin-top: 20px; text-align: center;">
                        <div class="col-md-6">
                            <div class="col-md-12 mg-bt">
                                <!-- <select id="fontSize">
                  <option value="80">80</option>
                  <option value="75">75</option>
                  <option value="70">70</option>
                  <option value="65">65</option>
                  <option value="60">60</option>
                  <option value="50">50</option>
                  <option value="40">40</option>
                </select> -->
                                <p style="margin-bottom: 5px;">Cỡ chữ</p>
                                <input class="slider" id="fontSize" type="range" min="40" max="200" value="40" />
                            </div>
                            <div class="col-md-12 mg-bt">
                                <p style="margin-bottom: 5px;">Thu / Phóng ảnh</p>
                                <input class="slider" id="scaleImage" type="range" min="0" max="200" value="100" />
                            </div>
                            <div class="col-md-12 mg-bt">
                                <p style="margin-bottom: 5px;">Xoay ảnh</p>
                                <input class="slider" id="angleImage" type="range" min="0" max="100" value="50" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="col-md-12 mg-bt">
                                <button (click)="uploader.click()"
                                    class="file-upload-browse btn btn-gradient-primary wd-100" type="button">
                                    <i class="mdi mdi-upload"></i>
                                    Tải ảnh lên</button>
                                <input #Image type="file" hidden #uploader id="file" class="file-upload-default"
                                    (change)="fileProgress($event)" />
                            </div>
                            <div class="col-md-12 mg-bt">
                                <button (click)="removeObject()"
                                    class="file-upload-browse btn btn-gradient-primary wd-100" type="button"><i
                                        class="mdi mdi-delete-forever"></i> Xóa
                                    Ảnh</button>
                            </div>
                            <div class="col-md-12 mg-bt">
                                <button (click)="openModal(mediumModalContent)" type="submit"
                                    class="btn btn-gradient-primary mr-2 wd-100">
                                    Xem kết quả
                                </button>
                            </div>
                            <div class="col-md-12 mg-bt">
                                <a href="/imgresponse" class="btn btn-gradient-primary mr-2 wd-100">
                                    Thư viện
                                </a>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <span style="font-size: initial; color: burlywood;text-align: left;">
                                <br> Sau khi nhập thông tin:<br>
                                - Bạn có thể nhấp vào thông tin trên ảnh để điều chỉnh vị trí cho phù hợp
                                <br> Sau khi tải ảnh lên:<br>
                                - Bạn có thể nhấp vào hình để kéo chỉnh sửa chiều dài rộng của hình ảnh<br><br>
                            </span>
                        </div>
                        <!-- <div class="col-md-6">
                            <div class="col-md-12 mg-bt">
                                <a href="/imgresponse" class="btn btn-gradient-primary mr-2 wd-100">
                                    Thư viện
                                </a>
                            </div>
                        </div> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<style>
    .mg-bt {
        margin-bottom: 10px;
    }

    .wd-100 {
        width: 100%;
    }
</style>
<!-- <div class="row" *ngIf="profileUsers.length > 0">
  <section class="container">
    <h1 class="my-4 text-center text-lg-left">Thư viện</h1>
    <div class="row gallery">
      <div class="col-lg-3 col-md-4 col-xs-6 thumb" *ngFor="let profileUser of profileUsers">
        <figure>
          <img #img class="img-fluid img-thumbnail" src="{{originBackend + profileUser.image}}"
            alt="{{profileUser.fullName}}">
        </figure>
      </div>
    </div>
  </section>
</div> -->


<!-- Modal preview ky vong -->
<ng-template #mediumModalContent let-modal class="modal fade" id="exampleModal-2" aria-labelledby="exampleModalLabel-2">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel-2">Hình ảnh kết quả</h5>
            <button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="position: relative;" [style.height]="imgHeightDownload">
            <div class="form-group row">
                <div class="col-sm-12">
                    <img id="imageDownload" style="position: absolute;" [style.height]="imgHeightDownload"
                        [style.width]="imgWidthDownload" src="{{imageDownload}}" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="box-btn" style="margin: 0 auto;">
                <button [hidden]="exist" type="button" style="margin-bottom: 20; margin-top: 20;"
                    class="btn btn-gradient-primary btn-fw" (click)="saveImageDB(true)"> Tải xuống</button>
                <button [hidden]="exist" type="button" style="margin-bottom: 20; margin-top: 20;"
                    class="btn btn-gradient-primary btn-fw" (click)="saveImageDB(false)"> Gửi cảm nhận và tải
                    xuống</button>

            </div>
            <span style="font-size: initial; color: burlywood;">
                Nếu không tải được ảnh:<br>
                - Máy tính: Ấn chuột phải => Chọn "Save Image As..."<br>
                - Điện thoại: Nhấn giữ tấm ảnh từ 5 giây trở lên sẽ hiển thị tùy chọn tải ảnh.
            </span>
        </div>
    </div>
</ng-template>
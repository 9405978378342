<div class="page-header">
    <h3 class="page-title">
        <span class="page-title-icon bg-gradient-primary text-white mr-2">
      <i class="mdi mdi-home"></i>
    </span> Cảm nhận đã gửi
    </h3>
</div>
<div class="row">
    <div *ngIf="profileUsers.length > 0">
        <section>
            <h1 class="my-4 text-center text-lg-left">Thư viện</h1>
            <div class="row gallery">
                <div class="col-lg-3 col-md-4 col-xs-6 thumb"  *ngFor="let profileUser of profileUsers">
                    <!-- <a #img href="/{{profileUser.image}}"> -->
                    <figure >
                        <img class="img-fluid img-thumbnail"  src="{{originBackend + profileUser.imageUrl}}" >
                    </figure>
                    <!-- </a> -->
                </div>
            </div>
            <!-- <div class="form-group row">
                <div class="col-xs-12 col-12"> -->
            <pagination [totalItems]="bigTotalItems" [itemsPerPage]="maxSize" class="pagination-danger" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" [boundaryLinks]="true" [rotate]="false" (numPages)="numPages = $event" (pageChanged)="pageChanged($event)"></pagination>
            <!-- </div>
            </div> -->
        </section>
    </div>
</div>
<div class="page-header">
    <h3 class="page-title">
        <span class="page-title-icon bg-gradient-primary text-white mr-2">
            <i class="mdi mdi-home"></i>
        </span> Văn Kiện Đại Hội
    </h3>
</div>
<div class="row">
    <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-danger card-img-holder text-white">
            <div class="card-body">
                <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                <h4 class="font-weight-normal mb-3">Video Clip
                    <i class="mdi mdi-chart-line mdi-24px float-right"></i>
                </h4>
                <h2 class="mb-5">{{videos}}</h2>
            </div>
        </div>
    </div>
    <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-info card-img-holder text-white">
            <div class="card-body">
                <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                <h4 class="font-weight-normal mb-3">Tài Liệu
                    <i class="mdi mdi-bookmark-outline mdi-24px float-right"></i>
                </h4>
                <h2 class="mb-5">{{documents}}</h2>
            </div>
        </div>
    </div>
    <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-success card-img-holder text-white">
            <div class="card-body">
                <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                <h4 class="font-weight-normal mb-3">Hình Ảnh
                    <i class="mdi mdi-diamond mdi-24px float-right"></i>
                </h4>
                <h2 class="mb-5">{{images}}</h2>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Tài Liệu</h4>
                <p class="card-description">Tài Liệu Đại Hội</p>
                <ngb-tabset type="pills" class="pills-success" *ngIf="documents != 0 ; else elseDocuments;">
                    <ngb-tab title="{{libD.name}}" *ngFor="let libD of libraryDocument.libraryDocuments">
                        <ng-template ngbTabContent>
                            <div class="card">
                                <div class="card-body" style="padding: 0;">
                                    <h4 class="card-title">Tài Liệu</h4>
                                    <div class="table-responsive pt-3">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Tên Tài Liệu</th>
                                                    <th>Tệp</th>
                                                    <th>Ngày Đăng</th>
                                                    <th>Hành Động</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let lDItems of libD.libraryDocumentItems">
                                                    <td style="text-align: center;">
                                                        <img [src]="formatFilePathTemp(lDItems.filePath)" class="mr-2"
                                                            alt="image"> {{getFileName(lDItems.fileName)}}
                                                    </td>
                                                    <td data-label="Tệp">
                                                        <label
                                                            class="badge badge-gradient-danger">{{getExtensionFile(lDItems.filePath)}}</label>
                                                    </td>
                                                    <td data-label="Ngày Đăng">
                                                        {{lDItems.uploadDate | date: 'dd/MM/yyyy'}}
                                                        <!-- {{lDItems}} -->
                                                    </td>
                                                    <td>
                                                        <button class="add btn btn-info todo-list-add-btn py-2"
                                                            (click)="openPreviewFile(lDItems)">Xem ngay</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
                <ng-template #elseDocuments>
                    Chưa có dữ liệu !
                </ng-template>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-6 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Hình Ảnh</h4>
                <p class="card-description">Hình Ảnh Đại Hội</p>
                <ngb-tabset type="pills" class="pills-success" *ngIf="images != 0 ; else elseImages;">
                    <ngb-tab title="{{libD.name}}" *ngFor="let libD of libraryImage.libraryDocuments">
                        <ng-template ngbTabContent>
                            <div class="row">
                                <div class="col-sm-3" *ngFor="let lDItems of libD.libraryDocumentItems">
                                    <img [src]="backendOrigin+lDItems.filePath" class="mb-2 mw-100 w-100 rounded"
                                        (click)="openMoadalFile(mediumModalContent,lDItems)" alt="image">
                                </div>
                            </div>
                            <!-- <div class="d-flex mt-5 align-items-top">
                                <img src="https://scontent.fsgn4-1.fna.fbcdn.net/v/t39.30808-6/277533929_295843329386470_7559640339381664365_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=-TItZ1LevDsAX-XN3Tk&_nc_ht=scontent.fsgn4-1.fna&oh=00_AT_XprsV5gsKtvHjr98u7d-Is_PqmL3UlYivWu8--oSofQ&oe=63310A05"
                                    class="img-sm rounded-circle mr-3" alt="image">
                                <div class="mb-0 flex-grow">
                                    <h5 class="mr-2 mb-2">Tuổi trẻ Đồng Nai</h5>
                                    <p class="mb-0 font-weight-light">22/09/2023</p>
                                </div>
                                <div class="ml-auto">
                                    <i class="mdi mdi-heart-outline text-muted"></i>
                                </div>
                            </div> -->
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
                <ng-template #elseImages>
                    Chưa có dữ liệu !
                </ng-template>
            </div>
        </div>
    </div>

    <div class="col-md-6 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Video CLip</h4>
                <p class="card-description">Video CLip Đại Hội </p>
                <ngb-tabset type="pills" class="pills-success" *ngIf="videos != 0 ; else elseVideos;">
                    <ngb-tab title="{{libD.name}}" *ngFor="let libD of libraryVideo.libraryDocuments">
                        <ng-template ngbTabContent>
                            <div class="row">
                                <div class="col-sm-6" *ngFor="let lDItems of libD.libraryDocumentItems">
                                    <video controls width="100%" #videoPlayer>
                                        <source [src]="backendOrigin+lDItems.filePath" type="video/ogg" />
                                        Browser not supported
                                    </video>
                                </div>
                            </div>

                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
                <ng-template #elseVideos>
                    <ngb-tabset>
                        <ngb-tab >
                            <ng-template ngbTabContent>
                                <div class="row">
                                    <iframe src="https://www.youtube.com/embed/VikCDgeeQHg?si=Tb9R8Ou4QIH6Cg_j"
                                        title="YouTube video player" frameborder="0"
                                        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </ng-template>
            </div>
        </div>
    </div>
</div>
<ng-template #mediumModalContent let-modal class="modal fade" id="exampleModal-2" aria-labelledby="exampleModalLabel-2">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel-2">Hình ảnh kết quả</h5>
            <button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group row">
                <div class="col-sm-12" style="text-align: center;">
                    <img [src]="backendOrigin+imgPath" width="100%" />
                </div>
            </div>
        </div>

    </div>
</ng-template>